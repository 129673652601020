/* Lighty Framework 1.0
 * Copyright 2018- Giant Advertising Ltd.
 ****************************************

 Module: Elements
 1. Basic inputs, textarea
 2. Radio and checkboxes
 3. Custom Select dropdown
 4. Notification

 */

@if $elements {
    
    @if $elements-forms {
        
        // form styling for basic input elements
        input,
        input[type="text"],
        input[type="email"],
        input[type="password"],
        input[type="submit"],
        input[type="button"],
        textarea {
            width: 100%;
            float: left;
            display: block;
            outline: none;
            height: $form-height;
            line-height: $form-height;
            padding: 0 $form-height / 4;
            margin: 0 0 ($form-height / 3) 0;
            color: $form-color;
            font-size: $form-font-size;
            font-weight: 400 !important;
            background-color: $form-background;
            border: $form-border;
            font-family: $paragraph-font-family;
            @include transition(all, $transition-3, ease);
            @include border-radius($form-border-radius);
            &:focus,
            &:active {
                background-color: $form-background-focus;
                @include transition(all, $transition-3, ease);
            }
        }
        
        // textarea styling fix
        textarea {
            min-height: 80px;
            line-height: $form-font-size * 1.8;
            padding: $form-height / 4;
        }
        
        // 100%, 50%, 33% form sizes
        .w_full {
            width: 100% !important;
        }
        .form-group {
            input,
            textarea,
            .btn {
                float: left !important;
                display: inline-block;
                &.w_50 {
                    width: 48% !important;
                    margin: 0 4% ($form-height / 3) 0 !important;
                    &:last-child {
                        margin: 0 0 ($form-height / 3) !important;
                    }
                }
                &.w_33 {
                    width: 30.666666% !important;
                    margin: 0 4% ($form-height / 3) 0 !important;
                    &:last-child {
                        margin: 0 0 ($form-height / 3) !important;
                    }
                }
            }
        }
        
        @media only screen and (max-width: $medium) {
            input,
            input[type="text"],
            input[type="email"],
            input[type="password"],
            input[type="submit"],
            input[type="button"],
            textarea {
                width: 100% !important;
                margin: 0 0 ($form-height / 3) 0;
                &.w_50,
                &.w_33 {
                    width: 100% !important;
                    margin: 0 0 ($form-height / 3) !important;
                }
            }
        }
        
        // placeholder colors
        input,
        textarea {
            @include input-placeholder {
                color: $input-plh-color;
            }
        }
        input:active,
        input:focus,
        textarea:active,
        textarea:focus {
            @include input-placeholder {
                color: $input-plh-color-focus;
            }
        }
        
        // hide hidden inputs
        input:hidden, input.hidden, input[type="hidden"] {
            display: none !important;
            visibility: hidden !important;
        }
        
        // submit button default color
        input[type="submit"], input[type="button"] {
            background-color: $primary-color;
            color: #FFFFFF;
            &:hover {
                background-color: darken($primary-color, 10%);
            }
        }
    }
    
    
    
    @if $elements-radio-checkbox {
        
        // custom radio and checkboxes
        .custom-form {
            display: block;
            position: relative;
            margin-bottom: 12px;
            cursor: pointer;
            font-size: 16px;
            line-height: 25px;
            padding-left: 33px;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;

            &.np {
                padding-left: 0 !important;
            }

            label {
                padding-left: 35px;
            }

            /* Hide the browser's default radio & checkbox */
            input[type="radio"],
            input[type="checkbox"] {
                position: absolute;
                opacity: 0;
            }

            /* When the checkbox is checked, add a blue background */
            input[type="radio"]:checked ~ .check,
            input[type="checkbox"]:checked ~ .check,
            input[type="radio"]:checked ~ .check:hover,
            input[type="checkbox"]:checked ~ .check:hover {
                background-color: $primary-color;
                border: 1px solid $primary-color;
            }

            /* Show the check when checked */
            input[type="radio"]:checked ~ .check:after,
            input[type="checkbox"]:checked ~ .check:after,
            input[type="radio"]:checked ~ .check:hover:after,
            input[type="checkbox"] ~ .check:hover:after {
              opacity: 1;
            }

            /* Create a custom radio & checkbox */
            input[type="radio"] ~ .check,
            input[type="checkbox"] ~ .check {
                position: absolute;
                top: 0;
                left: 0;
                height: 25px;
                width: 25px;
                background-color: #eee;
                @include border-radius($form-border-radius);
            }

            /* Create a custom radio & checkbox */
            input[type="radio"] ~ .check,
            input[type="checkbox"] ~ .check {
                position: absolute;
                top: 0;
                left: 0;
                background-color: white;
                border: 1px solid #e1e1e1;
                @include transition(all, $transition, ease);
                &:hover {
                    background-color: #ccc;
                    border: 1px solid #ccc;
                    @include transition(all, $transition-2, ease);
                }
            }
            input[type="radio"] ~ .check {
                height: 22px;
                width: 22px;
                border-radius: 50%;
                // use flexbox alignment for browser render bug
                @include flexbox();
                @include justify-content(center);
                @include align-items(center);
                &:hover:after {
                    opacity: 1;
                    background: white;
                    @include transition(all, $transition-2, ease);
                }
            }
            input[type="checkbox"] ~ .check {
                height: 25px;
                width: 25px;
            }

            /* Create the checkbox check indicator */
            input[type="checkbox"] ~ .check:after {
                content: "";
                position: absolute;
                opacity: 0;
                left: 9px;
                top: 4px;
                width: 7px;
                height: 12px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }

            /* Create the radio check indicator */
            input[type="radio"] ~ .check:after {
                content: "";
                //display: none; /* hidden when not checked */
                opacity: 0;
                top: 5px;
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background: white;
            }
        }
    }
    
    
    
    @if $elements-select {
        
        // clear the default browser appearance, show dd arrow
        select.custom {
            appearance:none;
            -moz-appearance:none;
            -webkit-appearance:none;
            position: relative;
            width: 100%;
            outline: 0 !important;
            background: url("../image/base/icon-ddown.png") no-repeat 98% center;
            background-size: 20px !important;
            @include border-radius($form-border-radius);
        }
        select.custom {
            width: 100% !important;
            float: left;
            display: block;
            padding: 0 $form-height / 4 !important;
            height: $form-height;
            line-height: $form-height;
            outline: none;
            font-size: $form-font-size;
            font-weight: 700 !important;
            font-family: "Open Sans", sans-serif;
            color: $input-plh-color !important;
            background-color: $form-background;
            border: $form-border;
            outline: none;
            @include transition(all, $transition-3, ease);
        }
    }
    
    @if $elements-notification {
        .notify {
            width: 100%;
            height: auto;
            display: block;
            padding: 16px 10px;
            margin-bottom: 20px;
            &.success {
                background-color: $color_success;
                color: #FFFFFF;
            }
            &.error {
                background-color: $color_danger;
                color: #FFFFFF;
            }
            &.info {
                background-color: $color_info;
                color: #FFFFFF;
            }
            &.warning {
                background-color: $color_warning;
                color: #FFFFFF;
            }
        }
    }
 
    
    label {
        float: left;
        text-align: left;
        font-size: 1.333rem;
        font-style: italic;
    }
    form label {
        display: block;
        width: 100%;
    }
    form .error {
        color: $color_danger;
    }
    form .okay {
        color: $color_success;
    }
    
}